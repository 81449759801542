<template>
    <div class="content-box">
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>资料管理</el-breadcrumb-item>
                <el-breadcrumb-item>产品资料</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="button-gutter">
                <el-button type="primary" @click="dialogVisible = true">上传本地文件</el-button>
                <!-- <el-button type="primary" @click="dialogVisible = true"
        >产品管理</el-button
      > -->
            </div>
            <div class="archive_mian">
                <div class="archive_left">
                    文档分类：
                    <el-cascader
                        :options="archiveOptions"
                        ref="cascader"
                        @blur="blur('cascader')"
                        @change="handleChange($event, proFrom)"
                    ></el-cascader>
                    <el-button style="margin-left: 15px" @click="clear('cascader')" type="primary">重置</el-button>
                </div>

                <div>
                    <el-table :data="tableData" border style="width: 900px" max-height="500">
                        <el-table-column prop="other.name" align="center" label="所属产品"> </el-table-column>
                        <el-table-column prop="fileName" align="center" label="文件名称"> </el-table-column>
                        <el-table-column prop="recordType" align="center" label="文件类型"> </el-table-column>
                        <el-table-column prop="timeCreated" align="center" label="上传时间"> </el-table-column>
                        <el-table-column align="center" label="操作">
                            <template slot-scope="scope">
                                <el-button
                                    @click="HandSee(scope.row, scope.$index)"
                                    style="margin-right: 12px"
                                    type="primary"
                                    size="mini"
                                    plain
                                >
                                    下载
                                </el-button>
                                <el-popconfirm
                                    confirm-button-text="确定"
                                    cancel-button-text="取消"
                                    confirm-button-type="danger"
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="确定删除吗？"
                                    @confirm="deleteFile(scope.row, scope.$index)"
                                >
                                    <el-button type="danger" slot="reference" size="mini" plain>删除</el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
                </div>
            </div>

            <el-dialog title="上传文件" :visible.sync="dialogVisible" width="30%" :before-close="Cancel">
                <el-form :model="documentForm">
                    <el-form-item label="档案分类">
                        <el-select v-model="documentForm.recordType" placeholder="请选择档案分类">
                            <el-option v-for="item in classifyOption" :label="item.label" :value="item.value" :key="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属产品">
                        <el-select v-model="documentForm.productId" placeholder="请选择所属产品">
                            <el-option v-for="item in productOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="文件类型">
                        <el-select v-model="documentForm.document" placeholder="请选择文件类型">
                            <el-option
                                v-for="item in documentTypeOptions"
                                :key="item.value"
                                :value="item.value"
                                :label="item.lable"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上传本地文档">
                        <el-upload
                            :headers="tokenData"
                            class="upload-demo"
                            :action="actions"
                            :before-remove="beforeRemove"
                            :data="uploadData"
                            :with-credentials="false"
                            :before-upload="beforeUpload"
                            :on-success="handleSuccess"
                        >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip">只能上传且不超过10m的文件</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="SendData(documentForm)">确定</el-button>
                </div>
            </el-dialog>

            <el-dialog title="文件信息" :visible.sync="seeDialogVisible" width="30%" :before-close="Cancel">
                <el-form :model="seeFrom">
                    <el-form-item label="文件名称">
                        <el-input :placeholder="seeFrom.fileName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="文件类型">
                        <el-input :placeholder="seeFrom.fileType" disabled />
                    </el-form-item>
                    <el-form-item label="档案类别">
                        <el-input :placeholder="seeFrom.name" disabled />
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <el-input :placeholder="seeFrom.timeCreated" disabled />
                    </el-form-item>
                </el-form>

                <span slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { fileDownload, productfileUpload } from '@/api/file';
import { productList } from '@/api/document';
import { fileList, deleteFileList, fileListType } from '@/api/product';

export default {
    name: 'Archive',
    components: {},
    data() {
        return {
            proFrom: {},
            disabled: true,
            actions: process.env.VUE_APP_BASE_API + `/archiveManagement/uploadFile`,
            dialogVisible: false,
            seeDialogVisible: false,
            tableData: [],
            currentPage: 1,
            pageSize: 10,
            num: 0,
            total: 0,
            documentForm: {},
            nowproFrom:{},
            seeFrom: {},
            documentTypeOptions: [
                {
                    value: '图片',
                    label: '图片'
                },
                {
                    value: '文档',
                    label: '文档'
                },
                {
                    value: '视频',
                    label: '视频'
                },
                {
                    value: '音频',
                    label: '音频'
                },
                {
                    value: '其他',
                    label: '其他'
                }
            ],
            classifyOption: [
                {
                    value: '客户需求',
                    label: '客户需求'
                },
                {
                    value: '技术解决方案',
                    label: '技术解决方案'
                },
                {
                    value: '设备信息',
                    label: '设备信息'
                },
                {
                    value: '供应商信息',
                    label: '供应商信息'
                },
                {
                    value: '故障信息',
                    label: '故障信息'
                },
                {
                    value: '维护记录',
                    label: '维护记录'
                },
                {
                    value: '二维图纸',
                    label: '二维图纸'
                },
                {
                    value: '三维模型',
                    label: '三维模型'
                },
                {
                    value: '工艺路线文件',
                    label: '工艺路线文件'
                }
            ],

            //供应商
            productOption: [],
            archiveOptions: [
                {
                    label: '',
                    value: '',
                    children: [
                        {
                            label: '',
                            value: ''
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        baseApi() {
            return process.env.VUE_APP_BASE_API;
        },
        tokenData() {
            return {
                Authorization: window.localStorage.getItem('token')
            };
        },
        uploadData() {
            return {
                recordType: this.documentForm.recordType,
                productId: this.documentForm.productId
            };
        }
    },
    mounted() {
        this.getFileList();
        this.GetProductList();
        this.getFile();
        this.getProductions();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            //  console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            if (this.proFrom.productId == '' || undefined) {
                this.getFile();

            } else {
                this.nowData()
            }
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },

        //获取归档表

        async getFile() {
            let page = this.currentPage;
            let limit = this.pageSize;
            let res = await fileList({
                limit,
                page
            });
            if (res.code == 200) {
                //   console.log(res.data);
                this.tableData = res.data;
                this.total = res.total;
                this.num = parseInt(this.total / limit + 1);
            }
        },

        // 获取select框
        async GetProductList() {
            let res = await fileListType({});

            if (res.code == 200) {
                console.log(res.data);

                this.archiveOptions = res.data;
            }
        },

        //获取产品下拉

        async getProductions() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let res = await productList({
                limit,
                page
            });
            if (res.code == 200) {
                //  console.log(res.data);
                let list = [];
                res.data.forEach((item) => {
                    let data = {};
                    data.label = item.name;
                    data.value = item.id;
                    list.push(data);
                });
                this.productOption = list;
            }
        },

        //  默认展开子节点
        ChangeAcitve(val) {
            if (val.length == 1) {
                this.$nextTick(() => {
                    let node = this.$refs.cascader.menus,
                        caspanel = this.$refs.cascader;
                    let userArr = node[0].filter((aa) => aa.value == val[0]);
                    caspanel.handleExpand(userArr, true);
                    caspanel.handleExpand(userArr[0], true);
                    caspanel.handleExpand(userArr[0], true);
                    caspanel.handleExpand(userArr[0].children[0], true);
                });
            }
        },

        //确定归档资料

        clear(cascader) {
            this.$refs['cascader'].panel.clearCheckedNodes();
            this.pageSize = 10
            this.currentPage =1
            this.nowproFrom ={}
            this.getFile();
        },

        async getFileList() {},
        async deleteFile(row) {
            let id = row.id;

            const res = await deleteFileList({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
            } else {
                this.$message.error('删除失败');
            }
            await this.getFile();
        },
        async downloadFile(id) {
            const res = await fileDownload(id);
            download(res, 'test');
        },
           async  nowData (){
            let limit = this.pageSize;
            let page = this.currentPage;
            const searchList = await fileList({
                recordType:this.nowproFrom.recordType,
                productId:this.nowproFrom.productId,
                limit,
                page
            });
            if (searchList.code == 200) {
                this.tableData = searchList.data;
                this.total = searchList.total;
                this.num = parseInt(this.total / limit + 1);
            }

           },
        async handleChange($event, proFrom) {
            this.proFrom.productId = $event[0];
            this.proFrom.recordType = $event[1];
            this.nowproFrom = this.proFrom
            this.currentPage = 1

            let productId = this.proFrom.productId;
            let recordType = this.proFrom.recordType;

            //(file_type);
            let page = this.currentPage;
            let limit = this.pageSize;
            const searchList = await fileList({
                recordType,
                productId,
                limit,
                page
            });
            if (searchList.code == 200) {
                this.tableData = searchList.data;
                this.total = searchList.total;
                this.num = parseInt(this.total / limit + 1);
            }
            //    console.log(value);
        },

        async HandSee(row, $index) {
            let id = row.id;
            //  window.location.href=process.env.VUE_APP_BASE_API+`/archiveManagement/download?id=${row.id}`

            // console.log(process.env.VUE_APP_BASE_API)
            const res = await productfileUpload({ id });

                let url = window.URL.createObjectURL(new Blob([res]));
                // const url = new Blob([res])
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.download = row.fileName + row.fileType;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

        },

        //上传文件
        handleSuccess(response, file) {
            console.log(response);
            if (response.code == 200) {
                this.disabled = false;
                this.contractFrom.contractFileId = response.data;
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
                console.log(response.data);
                this.disabled = false;
            } else {
                this.$message.error('上传失败');
            }
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        Cancel() {
            this.dialogVisible = false;
            this.seeDialogVisible = false;
            this.documentForm = {};
        },
        SendData() {
            console.log(this.documentForm);
            this.documentForm = {};
            this.$message.success('上传成功');
            this.getFile();
            this.dialogVisible = false;
        },

        beforeUpload(file) {
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isLt10M) {
                this.$message.error('上传文件大小不能超过 10MB!');
            }
            return isLt10M;
        },
        blur(cascader) {
            console.log(this.$refs[cascader]);
        }
    }
};
</script>


<style lang="scss" scoped>
.button-gutter {
    line-height: 80px;
    margin: 20px 0;
    border-bottom: 1px solid black;
}
.archive_mian {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}
.archive_left {
    width: 460px;
    height: 400px;
}
.el-cascader-panel {
    height: 300px;
    .el-scrollbar__wrap {
        height: 100%;
    }
}
::v-deep.el-cascader-panel {
    height: 500px;
}
::v-deep .el-cascader-menu__wrap {
    height: 540px;
}
::v-deep .el-dialog__header {
    text-align: center;
}
.footer {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
}
</style>
